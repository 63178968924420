<template>
  <base-page-item>
    <v-card class="pa-16 ma-16">
      <v-row
        class="spacer mb-6"
        no-gutters
      >
        <v-col cols="3">
          <v-row
            align="center"
            class="spacer"
            no-gutters
          >
            <!--          compass-->
            <v-col
              cols="2"
              class="text-center"
            >
              <v-avatar size="36px">
                <img
                  v-if="credential.photo_link && !failedImg"
                  alt="Avatar"
                  :src="credential.photo_link"
                  @error="failedImg = true"
                >
                <v-icon v-else>
                  mdi-account-settings-outline
                </v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="10">
              <p class="text-left mb-0 font-weight-bold">
                {{ credential.full_name }}
              </p>
              <p class="text-left mb-0">
                {{ credential.username }}
              </p>
              <p class="text-left mb-0">
                space: {{ credential.space.email }}
              </p>
              <a
                :href="`https://x.divo.ai/campaigns?space=${credential.space._id}&selectedCred=${credential._id}`"
                class="href"
                target="_blank"
              > Open in Divo </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <template v-if="credential.worker">
            <p class="text-left mb-0">
              <span class="font-weight-bold mr-1">Worker name:</span> {{ credential.worker.device_name }}
            </p>
            <p class="text-left mb-0">
              <span class="font-weight-bold mr-1">Worker ip:</span> {{ credential.worker.ip_address }}
            </p>
            <p class="text-left mb-0">
              <span class="font-weight-bold mr-1">Worker instance:</span> {{ credential.worker.instance_number }}
            </p>
            <p class="text-left mb-0">
              <a
                :href="href"
                class="href"
                target="_blank"
              > Open panel </a>
            </p>
          </template>
          <p
            v-else
            class="text-left mb-0"
          >
            No worker
          </p>
        </v-col>
        <v-col cols="3">
          <p
            v-if="credential.status === 'active'"
            class="text-left mb-0"
          >
            <span class="font-weight-bold mr-1">Status on server:</span> {{ credentialStatusOnServer }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-2">Account status:</span>
            <span
              :class="{
                'success--text': credential.status === 'active',
                'grey--text': credential.status === 'inactive',
                'warning--text': credential.status === 'invalid',
                'error--text': credential.status === 'deleted',
              }"
              class="text-capitalize "
            >{{ credential.status }}
            </span>
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-2">Campaigns:</span>
            <span class="text-capitalize">Active: {{ credential.workflows.filter(workflow => workflow.status === 'active').length }} (all: {{ credential.workflows.length }})</span>
          </p>
        </v-col>
        <v-col cols="2">
          <p
            v-if="credential.connections_checked_at"
            class="text-left mb-0"
          >
            {{ credential.connections_checked_at | fromNow }} (connections)
          </p>
          <p
            v-if="credential.dialogs_checked_at"
            class="text-left mb-0"
          >
            {{ credential.dialogs_checked_at | fromNow }} (dialogs)
          </p>
        </v-col>
        <v-col cols="2">
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Account type:</span> {{ credential.account_type }}
          </p>
          <p class="text-left mb-2">
            <span class="font-weight-bold mr-1">Сreated at:</span> {{ credential.created_at }}
          </p>
          <p class="text-left mb-2">
            <span class="font-weight-bold mr-1">Proxy status:</span>
            <span
              :class="[credential.proxy_failed_at ? 'warning--text' : 'success--text']"
            >{{ proxyStatus }}</span>
          </p>
          <v-text-field
            v-model="newProxyAddress"
            class="mb-2"
            label="Enter address"
          />
          <v-btn
            class="mb-2"
            :disabled="!newProxyAddress"
            @click="setNewProxy"
          >
            Set Proxy
          </v-btn>
          <v-btn
            v-if="credential.worker"
            @click="updateWorker"
          >
            {{ credential.worker.status === 'active' ? 'Pause' : 'Resume' }}
          </v-btn>
        </v-col>
      </v-row>

      <v-chip
        v-for="type of byTaskTypeCredentials"
        :key="type.type"
        class="ma-2"
        outlined
        @click="forwardToTask(type)"
      >
        <span class="mr-2"> {{ type.type }} </span>
        <span
          v-if="type.done"
          class="font-weight-bold success--text mr-2"
        > {{ type.done }} </span>
        <span
          v-if="type.failed"
          class="font-weight-bold error--text"
        > {{ type.failed }} </span>
      </v-chip>
    </v-card>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';
  import { getPanelHref } from '@/mixins/getPanelHref';
  import { setProxyForCred } from '@/api/proxiesMethods';
  import { updateWorker } from '@/api/workersMethods';

  export default {
    name: 'CredentialItem',
    components: {
      BasePageItem,
    },
    mixins: [fromNowFilterMixin, getPanelHref],
    props: {
      credential: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        failedImg: false,
        newProxyAddress: null,
      };
    },
    computed: {
      credentialStatusOnServer () {
        if (this.credential.challenged_at) {
          return 'Waiting for resolving challenge';
        }
        if (this.credential.code_requested_at) {
          return 'Waiting for security code';
        }

        return 'In work';
      },
      byTaskTypeCredentials () {
        return this.credential.by_task_type.filter(type => type.done || type.failed);
      },
      href () {
        return this.getPanelHref(this.credential.worker.device_name);
      },
      proxyStatus () {
        return this.credential.proxy_failed_at ? 'Failed at ' + this.credential.proxy_failed_at : 'Active';
      },
    },
    methods: {
      setNewProxy () {
        setProxyForCred(this.credential._id, this.newProxyAddress);
        this.newProxyAddress = '';
      },
      async forwardToTask (type) {
        this.$store.dispatch('tasks/SET_FILTERS', { status: { value: '' }, type: { value: type.type }, credentials: { value: this.credential._id } });
        await this.$router.push({ name: 'tasks' });
      },
      async updateWorker () {
        const status = this.credential.worker.status === 'active' ? 'inactive' : 'active';
        updateWorker(this.credential.worker._id, false, status);
      },
    },
  };
</script>

<style scoped>

</style>
